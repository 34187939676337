<template>
  <el-dialog :title="title" :visible.sync="visible" width="500px" append-to-body>
    <el-form ref="form" :model="form" label-width="100px" :rules="rules">
      <el-form-item label="今日U价" prop="amount">
        <el-input v-model="form.rate" placeholder="请输入今日U价"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>

import {getUsdtRate, setUsdtRate} from "@/api/merchant/merchant";

export default {
  name: "SetUsdtRate",
  components: {},
  props: {
    type: {
      type: String,
      default: "collection"
    }
  },
  data() {
    return {
      visible: false,
      merchants: [],
      title: "设置今日U价",
      form: {
        rate: 0.0
      },
      rules: {
        merchantIds: [
          {required: true, message: "请选择商户", trigger: "blur"}
        ]
      }
    };
  },
  mounted() {
  },
  methods: {
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          setUsdtRate(this.form.rate).then(res => {
            this.visible = false
          })
        }
      });
    },
    cancel() {
      this.visible = false;
    },
    async open() {
      this.visible = true;
      getUsdtRate().then(res => {
        this.form.rate = res.data;
      })
    }
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
