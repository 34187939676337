var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.info, "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "可提现金额", prop: "available" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.info.available,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "available", $$v)
                  },
                  expression: "info.available",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "今日U价", prop: "rate" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.info.rate,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "rate", $$v)
                  },
                  expression: "info.rate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回U地址", prop: "address" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "请输入内容" },
                model: {
                  value: _vm.info.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "address", $$v)
                  },
                  expression: "info.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提现USDT", prop: "usdt" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入提现USDT" },
                on: { input: _vm.updateUsdt },
                model: {
                  value: _vm.info.usdt,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "usdt", $$v)
                  },
                  expression: "info.usdt",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提现金额", prop: "amount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入提现金额" },
                on: { input: _vm.updateamount },
                model: {
                  value: _vm.info.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "amount", $$v)
                  },
                  expression: "info.amount",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v("确 定")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }