<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="100px">
      <el-form-item label="订单号" prop="orderNo">
        <el-input
            v-model="queryParams.orderNo"
            placeholder="请输入订单号"
            clearable
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="支付类型" prop="type">
        <el-input
            v-model="queryParams.type"
            placeholder="请输入支付类型"
            clearable
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <!--      <el-form-item label="商家订单号" prop="merchantOrderNo">-->
      <!--        <el-input-->
      <!--            v-model="queryParams.merchantOrderNo"-->
      <!--            placeholder="请输入商家订单号"-->
      <!--            clearable-->
      <!--            @keyup.enter.native="handleQuery"-->
      <!--        />-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="请求ID" prop="requestId">-->
      <!--        <el-input-->
      <!--            v-model="queryParams.requestId"-->
      <!--            placeholder="请输入请求ID"-->
      <!--            clearable-->
      <!--            @keyup.enter.native="handleQuery"-->
      <!--        />-->
      <!--      </el-form-item>-->
      <el-form-item label="UTR" prop="utr">
        <el-input
            v-model="queryParams.utr"
            placeholder="请输入utr"
            clearable
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="交易金额" prop="amount">
        <el-input
            v-model="queryParams.amount"
            placeholder="请输入交易金额"
            clearable
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="通道号" prop="channelNo">
        <all-channel :form="queryParams" type="payment"/>
      </el-form-item>
      <el-form-item label="商家名称" prop="merchantId">
        <merchant-list :form="queryParams"/>
      </el-form-item>
      <el-form-item label="订单状态" prop="status">
        <order-status-list :form="queryParams" search-type="payOut"/>
      </el-form-item>
      <el-form-item label="创建时间" prop="status">
        <search-time :query-params="queryParams"/>
      </el-form-item>
      <br>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="warning" size="mini" @click="callbackOpen=true;isTest=true;callbackKey=callbackKey+1">
          测试回调
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" size="mini" @click="callbackOpen=true;isTest=false;callbackKey=callbackKey+1">
          正式回调
        </el-button>
      </el-col>
      <!--      <el-col :span="1.5">-->
      <!--        <el-button type="success" size="mini" @click="callSync">-->
      <!--          同步数据-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <el-col :span="1.5">
        <el-button type="danger" size="mini" @click="changeChannelNoInfo.open=true">
          批量修改通道
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="warning"
            plain
            icon="el-icon-download"
            size="mini"
            @click="handleExport"
        >导出
        </el-button>
      </el-col>
      <!--      <el-col :span="1.5">-->
      <!--        <upload-pay-out/>-->
      <!--      </el-col>-->
    </el-row>

    <!-- 添加或修改代收对话框 -->
    <el-dialog title="手动补单" :visible.sync="cranesPayCallbackInfo.open" width="500px" append-to-body>
      <cranes-pay-callback :key="cranesPayCallbackInfo.key" :info="cranesPayCallbackInfo"
                           @cancel="cranesPayCallbackInfo.open=false"/>
    </el-dialog>

    <el-dialog title="手动回调" :visible.sync="callbackOpen" width="1000px" append-to-body>
      <callback @cancel="callbackOpen=false;getList()" :isTest="isTest" :key="callbackKey"/>
    </el-dialog>

    <el-dialog title="指修改通道" :visible.sync="changeChannelNoInfo.open" width="300px" append-to-body>
      <change-channel-no :key="changeChannelNoInfo.key" :info="changeChannelNoInfo" :ids="ids"
                         @cancel="changeChannelNoInfo.open=false"/>
    </el-dialog>

    <el-table v-loading="loading" :data="paymentList" @selection-change="handleSelectionChange"
              show-summary :summary-method="getSummaries">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="创建时间" align="center" prop="createdTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createdTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="通道号" align="center" prop="channelNo" width="60"/>
      <el-table-column label="完成时间" align="center" prop="completeTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.payTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="请求耗时(秒)" align="center" prop="requestTime" width="100"/>
      <el-table-column label="提交时间" align="center" prop="submitTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.submitTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平台订单号" align="center" prop="orderNo" width="100"/>
      <el-table-column label="上游订单号" align="center" prop="superiorNo" width="100"/>
      <el-table-column label="商家订单号" align="center" prop="merchantOrderNo" width="100"/>
      <el-table-column label="UTR" align="center" prop="utr" width="100"/>
      <el-table-column label="交易金额" align="center" prop="amount" width="80"/>
      <!--      <el-table-column v-if="isAdmin" label="应付金额" align="center" prop="shouldAmount" width="80"/>-->
      <!--      <el-table-column v-if="isAdmin" label="实付金额" align="center" prop="realAmount" width="80"/>-->
      <el-table-column label="订单状态" align="center" width="140">
        <template slot-scope="scope">
          <el-button type="success" v-if="scope.row.status=='已支付'" size="small" round>{{ scope.row.status }}
          </el-button>
          <el-button type="danger" v-if="scope.row.status=='支付失败'" size="small" round>{{ scope.row.status }}
          </el-button>
          <el-button type="danger" v-if="scope.row.status=='提交失败'" size="small" round>{{ scope.row.status }}
          </el-button>
          <el-button type="warning" v-if="scope.row.status=='支付中'" size="small" round>{{ scope.row.status }}
          </el-button>
          <el-button type="info" v-if="scope.row.status=='已处理'" size="small" round>{{ scope.row.status }}</el-button>
          <el-button type="info" v-if="scope.row.status=='等待重提'" size="small" round>{{ scope.row.status }}
          </el-button>
        </template>
      </el-table-column>
      <!--      <el-table-column label="重试次数" align="center" prop="recount" />-->
      <!--      <el-table-column v-if="isAdmin" label="通道费率" align="center" prop="channelRate" width="80"/>-->
      <el-table-column v-if="isAdmin" label="通道手续费" align="center" prop="channelFee" width="100"/>
      <!--      <el-table-column v-if="isAdmin" label="单笔手续费" align="center" prop="channelSingleFee" width="100"/>-->
      <el-table-column label="商家id" align="center" prop="merchantId" width="80"/>
      <!--      <el-table-column label="商家费率" align="center" prop="merchantRate" width="80"/>-->
      <el-table-column label="商家手续费" align="center" prop="merchantFee" width="100"/>
      <el-table-column label="单笔手续费" align="center" prop="merchantSingleFee" width="100"/>
      <el-table-column label="商户结算金额" align="center" prop="merchantSettle" width="120"/>
      <el-table-column v-if="isAdmin" label="收益" align="center" prop="income" width="60"/>
      <!--      <el-table-column label="付款用户id" align="center" prop="userId" width="120"/>-->
      <el-table-column label="支付类型" align="center" prop="payType" width="80"/>
      <el-table-column label="ifsc code" align="center" prop="ifscCode" width="100"/>
      <el-table-column label="用户收款账号" align="center" prop="userBankAccount" width="120"/>
      <el-table-column label="收款用户名" align="center" prop="userName" width="100"/>
            <el-table-column label="收款用户手机" align="center" prop="userPhone" width="120"/>
      <!--      <el-table-column label="收款用户邮箱" align="center" prop="userEmail" width="120"/>-->
      <!--      <el-table-column label="收款银行代码" align="center" prop="bankCode" width="120"/>-->
      <!--      <el-table-column label="收款银行户名" align="center" prop="receiveName" width="120"/>-->

      <el-table-column label="失败原因" align="center" prop="failReason" width="80" :show-overflow-tooltip="true"/>
      <!--      <el-table-column label="其它参数" align="center" prop="attach" width="80"/>-->
      <!--      <el-table-column label="mq消费id" align="center" prop="msgId" width="120"/>-->
            <el-table-column label="请求ID" align="center" prop="requestId" width="120"/>
      <!--      <el-table-column label="更新时间" align="center" prop="updatedTime" width="180">-->
      <!--        <template slot-scope="scope">-->
      <!--          <span>{{ parseTime(scope.row.updatedTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right" width="130">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleQryByOrderNo(scope.row)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleQueryThree(scope.row)"
          >上游查单
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 修改流水对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="平台订单号" prop="orderNo">
          <el-input v-model="form.orderNo" placeholder="平台订单号" :disabled="true"/>
        </el-form-item>
        <el-form-item label="上游流水号" prop="superiorNo">
          <el-input v-model="form.superiorNo" placeholder="上游流水号" :disabled="true"/>
        </el-form-item>
        <el-form-item label="商家流水号" prop="merchantOrderNo">
          <el-input v-model="form.merchantOrderNo" placeholder="商家流水号" :disabled="true"/>
        </el-form-item>
        <el-form-item label="交易金额" prop="amount">
          <el-input v-model="form.amount" placeholder="交易金额" :disabled="true"/>
        </el-form-item>
        <el-form-item label="通道号" prop="channelNo">
          <!--          <el-input v-model="form.channelNo" placeholder="通道号"/>-->
          <all-channel :form="form" type="payment"/>
        </el-form-item>
        <el-form-item label="UTR" prop="utr">
          <el-input v-model="form.utr" placeholder="utr"/>
        </el-form-item>
        <el-form-item label="订单状态" prop="status">
          <order-status-list :form="form" search-type="payOut"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 修改流水对话框 -->
    <el-dialog :title="title" :visible.sync="open1" width="500px" append-to-body>
      <el-form ref="form1" :model="form1" :rules="rules" label-width="100px">
        <el-form-item label="上游订单状态" prop="status">
          <el-input v-model="form1.status" :disabled="true"/>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  listPayment,
  getPayment,
  delPayment,
  addPayment,
  updatePayment,
  getPaymentByOrderNo,
  getPartnerStatus,
  sync
} from "@/api/order/payment";
import MerchantList from "@/components/Query/merchantList";
import ChannelQueryType from "@/components/Query/ChannelQueryType";
import ChannelType from "@/components/Query/ChannelType";
import AllChannel from "@/components/Query/AllChannel";
import OrderStatusList from "@/components/Query/OrderStatusList";
import Callback from "@/views/order/payment/callback";
import SearchTime from "@/components/searchTime";
import CranesPayCallback from "@/views/order/payment/cranesPayCallback";
import CranesPayUploadCallback from "@/views/order/payment/cranesPayUploadCallback";
import UploadPayOut from "@/views/order/payment/UploadPayOut/index.vue";
import ChangeChannelNo from "@/views/order/payment/changeChannelNo/index.vue";
import dayjs from "dayjs";

export default {
  name: "Payment",
  components: {
    ChangeChannelNo,
    UploadPayOut,
    CranesPayUploadCallback,
    CranesPayCallback,
    SearchTime, Callback, OrderStatusList, AllChannel, ChannelType, ChannelQueryType, MerchantList
  },
  data() {
    return {
      callbackKey: 0,
      //回调列表
      callbackOpen: false,
      cranesPayCallbackInfo: {
        key: 0,
        open: false,
      },
      changeChannelNoInfo: {
        key: 0,
        open: false,
      },
      isTest: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 代付表格数据
      paymentList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      open1: false,
      searchTimeKey: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: null,
        orderNo: null,
        superiorNo: null,
        amount: null,
        shouldAmount: null,
        realAmount: null,
        status: null,
        channelNo: null,
        channelRate: null,
        fee: null,
        merchantId: null,
        merchantRate: null,
        merchantFee: null,
        singleFee: null,
        merchantOrderNo: null,
        merchantSettle: null,
        income: null,
        userId: null,
        userBankAccount: null,
        userName: null,
        userPhone: null,
        userEmail: null,
        bankCode: null,
        receiveName: null,
        failReason: null,
        attach: null,
        notifyUrl: null,
        tenantId: null,
        createdTime: null,
        updatedBy: null,
        requestId: null,
        updatedTime: null,
        searchTime: [dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss'),dayjs().format('YYYY-MM-DD HH:mm:ss')]
      },
      // 表单参数
      form: {},
      form1: {},
      // 表单校验
      rules: {},
      isAdmin: false
    };
  },
  created() {
    this.getList();
  },
  methods: {
    callSync() {
      sync().then(res => {
        this.$modal.msgSuccess(res.msg);
      })
    },
    /** 查询代付列表 */
    getList() {
      this.loading = true;
      this.queryParams.merchantOrderNo = this.queryParams.orderNo
      this.queryParams.superiorNo = this.queryParams.orderNo
      listPayment(this.queryParams).then(response => {
        this.isAdmin = response.admin
        this.paymentList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        orderNo: null,
        superiorNo: null,
        amount: null,
        shouldAmount: null,
        realAmount: null,
        status: "0",
        channelNo: null,
        channelRate: null,
        fee: null,
        merchantId: null,
        merchantRate: null,
        merchantFee: null,
        singleFee: null,
        merchantOrderNo: null,
        merchantSettle: null,
        income: null,
        userId: null,
        userBankAccount: null,
        userName: null,
        userPhone: null,
        userEmail: null,
        bankCode: null,
        requestId: null,
        receiveName: null,
        failReason: null,
        attach: null,
        notifyUrl: null,
        tenantId: null,
        createdTime: null,
        updatedBy: null,
        updatedTime: null,
        searchTime: [dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss'),dayjs().format('YYYY-MM-DD HH:mm:ss')]
      };
      this.resetForm("form");
    },

    reset1() {
      this.form1 = {}
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.searchTime = [dayjs().add(-1, 'day').format('YYYY-MM-DD HH:mm:ss'),dayjs().format('YYYY-MM-DD HH:mm:ss')]
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加代付";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getPayment(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改代付";
      });
    },
    /** 订单号查询代付记录 */
    handleQryByOrderNo(row) {
      this.reset();
      const orderNo = row.orderNo
      if (row.status == "已支付" || row.status == "支付失败") {
        this.$message.error("支付成功和支付失败不允许更改状态！");
        return;
      }
      this.open = false;
      getPaymentByOrderNo(orderNo).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改代付";
      });

    },
    /** 查询三方订单状态 */
    handleQueryThree(row) {
      this.reset1();
      const orderNo = row.orderNo
      getPartnerStatus(orderNo).then(response => {
        this.form1 = response.data;

        this.open1 = true;
        this.title = "上游状态查询";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updatePayment(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addPayment(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除代付编号为"' + ids + '"的数据项？').then(function () {
        return delPayment(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('order/payment/export', {
        ...this.queryParams
      }, `payment_${new Date().getTime()}.xlsx`)
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      const property = ['amount', 'realAmount', 'channelFee', 'channelSingleFee', 'merchantFee', 'merchantSingleFee', 'merchantSettle', 'income']
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (property.indexOf(column.property) < 0) {
          return;
        }
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(2)
          // sums[index] += ' INR';
        } else {
          // sums[index] = 'N/A';
        }
      });

      return sums;
    }
  }
};
</script>
