<template>
  <div>
    <el-form ref="form" :model="info" label-width="100px">
      <el-form-item label="可提现金额" prop="available">
        <el-input v-model="info.available" :disabled="true"/>
      </el-form-item>
      <el-form-item label="今日U价" prop="rate">
        <el-input v-model="info.rate" :disabled="true"/>
      </el-form-item>
      <el-form-item label="回U地址" prop="address">
        <el-input v-model="info.address" type="textarea" placeholder="请输入内容"/>
      </el-form-item>
      <el-form-item label="提现USDT" prop="usdt">
        <el-input v-model="info.usdt" placeholder="请输入提现USDT" @input="updateUsdt"/>
      </el-form-item>
      <el-form-item label="提现金额" prop="amount">
        <el-input v-model="info.amount" placeholder="请输入提现金额" @input="updateamount"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {getUsdtRate, listMerchant} from "@/api/merchant/merchant";
import {addWithdrawal} from "@/api/merchant/withdrawal";

export default {
  name: "UserWithdrawal",
  components: {},
  props: ['info'],
  data() {
    return {};
  },
  mounted() {
    let that = this;
    getUsdtRate().then(res => {
      that.info.rate = res.data;
    })
    listMerchant({'id': that.info.merchantId}).then(response => {
      // console.log(response.rows[0].available);
      that.info.available = response.rows[0].available;
    })
  },
  methods: {
    submitForm() {
      addWithdrawal(this.info).then(res => {
        if (res.code === 200) {
          this.$modal.msgSuccess("提交成功");
          this.$emit('cancel')
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    updateUsdt(value) {
      let amount = Math.floor(value * this.info.rate);
      if (amount < this.info.available) {
        this.info.amount = amount
      } else {
        this.info.amount = 0
      }
    },
    updateamount(value) {
      if (value < this.info.available) {
        this.info.usdt = Math.floor(this.info.amount / this.info.rate).toFixed(0);
      } else {
        this.info.usdt = 0
      }
    }
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
