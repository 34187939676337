var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单号", prop: "orderNo" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入订单号", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "orderNo", $$v)
                  },
                  expression: "queryParams.orderNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付类型", prop: "type" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入支付类型", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "type", $$v)
                  },
                  expression: "queryParams.type",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "UTR", prop: "utr" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入utr", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.utr,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "utr", $$v)
                  },
                  expression: "queryParams.utr",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "交易金额", prop: "amount" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入交易金额", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "amount", $$v)
                  },
                  expression: "queryParams.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通道号", prop: "channelNo" } },
            [
              _c("all-channel", {
                attrs: { form: _vm.queryParams, type: "payment" },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商家名称", prop: "merchantId" } },
            [_c("merchant-list", { attrs: { form: _vm.queryParams } })],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态", prop: "status" } },
            [
              _c("order-status-list", {
                attrs: { form: _vm.queryParams, "search-type": "payOut" },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "status" } },
            [_c("search-time", { attrs: { "query-params": _vm.queryParams } })],
            1
          ),
          _c("br"),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.callbackOpen = true
                      _vm.isTest = true
                      _vm.callbackKey = _vm.callbackKey + 1
                    },
                  },
                },
                [_vm._v(" 测试回调 ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.callbackOpen = true
                      _vm.isTest = false
                      _vm.callbackKey = _vm.callbackKey + 1
                    },
                  },
                },
                [_vm._v(" 正式回调 ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.changeChannelNoInfo.open = true
                    },
                  },
                },
                [_vm._v(" 批量修改通道 ")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    plain: "",
                    icon: "el-icon-download",
                    size: "mini",
                  },
                  on: { click: _vm.handleExport },
                },
                [_vm._v("导出 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手动补单",
            visible: _vm.cranesPayCallbackInfo.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.cranesPayCallbackInfo, "open", $event)
            },
          },
        },
        [
          _c("cranes-pay-callback", {
            key: _vm.cranesPayCallbackInfo.key,
            attrs: { info: _vm.cranesPayCallbackInfo },
            on: {
              cancel: function ($event) {
                _vm.cranesPayCallbackInfo.open = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手动回调",
            visible: _vm.callbackOpen,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.callbackOpen = $event
            },
          },
        },
        [
          _c("callback", {
            key: _vm.callbackKey,
            attrs: { isTest: _vm.isTest },
            on: {
              cancel: function ($event) {
                _vm.callbackOpen = false
                _vm.getList()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "指修改通道",
            visible: _vm.changeChannelNoInfo.open,
            width: "300px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.changeChannelNoInfo, "open", $event)
            },
          },
        },
        [
          _c("change-channel-no", {
            key: _vm.changeChannelNoInfo.key,
            attrs: { info: _vm.changeChannelNoInfo, ids: _vm.ids },
            on: {
              cancel: function ($event) {
                _vm.changeChannelNoInfo.open = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.paymentList,
            "show-summary": "",
            "summary-method": _vm.getSummaries,
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              align: "center",
              prop: "createdTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.parseTime(
                            scope.row.createdTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "通道号",
              align: "center",
              prop: "channelNo",
              width: "60",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "完成时间",
              align: "center",
              prop: "completeTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.parseTime(
                            scope.row.payTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "请求耗时(秒)",
              align: "center",
              prop: "requestTime",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "提交时间",
              align: "center",
              prop: "submitTime",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.parseTime(
                            scope.row.submitTime,
                            "{y}-{m}-{d} {h}:{i}:{s}"
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "平台订单号",
              align: "center",
              prop: "orderNo",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "上游订单号",
              align: "center",
              prop: "superiorNo",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商家订单号",
              align: "center",
              prop: "merchantOrderNo",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "UTR", align: "center", prop: "utr", width: "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "交易金额",
              align: "center",
              prop: "amount",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "订单状态", align: "center", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == "已支付"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "small",
                              round: "",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.status) + " ")]
                        )
                      : _vm._e(),
                    scope.row.status == "支付失败"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "small", round: "" },
                          },
                          [_vm._v(_vm._s(scope.row.status) + " ")]
                        )
                      : _vm._e(),
                    scope.row.status == "提交失败"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "small", round: "" },
                          },
                          [_vm._v(_vm._s(scope.row.status) + " ")]
                        )
                      : _vm._e(),
                    scope.row.status == "支付中"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              size: "small",
                              round: "",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.status) + " ")]
                        )
                      : _vm._e(),
                    scope.row.status == "已处理"
                      ? _c(
                          "el-button",
                          { attrs: { type: "info", size: "small", round: "" } },
                          [_vm._v(_vm._s(scope.row.status))]
                        )
                      : _vm._e(),
                    scope.row.status == "等待重提"
                      ? _c(
                          "el-button",
                          { attrs: { type: "info", size: "small", round: "" } },
                          [_vm._v(_vm._s(scope.row.status) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm.isAdmin
            ? _c("el-table-column", {
                attrs: {
                  label: "通道手续费",
                  align: "center",
                  prop: "channelFee",
                  width: "100",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: "商家id",
              align: "center",
              prop: "merchantId",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商家手续费",
              align: "center",
              prop: "merchantFee",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "单笔手续费",
              align: "center",
              prop: "merchantSingleFee",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商户结算金额",
              align: "center",
              prop: "merchantSettle",
              width: "120",
            },
          }),
          _vm.isAdmin
            ? _c("el-table-column", {
                attrs: {
                  label: "收益",
                  align: "center",
                  prop: "income",
                  width: "60",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: "支付类型",
              align: "center",
              prop: "payType",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "ifsc code",
              align: "center",
              prop: "ifscCode",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "用户收款账号",
              align: "center",
              prop: "userBankAccount",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "收款用户名",
              align: "center",
              prop: "userName",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "收款用户手机",
              align: "center",
              prop: "userPhone",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "失败原因",
              align: "center",
              prop: "failReason",
              width: "80",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "请求ID",
              align: "center",
              prop: "requestId",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
              fixed: "right",
              width: "130",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleQryByOrderNo(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleQueryThree(scope.row)
                          },
                        },
                      },
                      [_vm._v("上游查单 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台订单号", prop: "orderNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "平台订单号", disabled: true },
                    model: {
                      value: _vm.form.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderNo", $$v)
                      },
                      expression: "form.orderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上游流水号", prop: "superiorNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "上游流水号", disabled: true },
                    model: {
                      value: _vm.form.superiorNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "superiorNo", $$v)
                      },
                      expression: "form.superiorNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商家流水号", prop: "merchantOrderNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "商家流水号", disabled: true },
                    model: {
                      value: _vm.form.merchantOrderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "merchantOrderNo", $$v)
                      },
                      expression: "form.merchantOrderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交易金额", prop: "amount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "交易金额", disabled: true },
                    model: {
                      value: _vm.form.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "amount", $$v)
                      },
                      expression: "form.amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "通道号", prop: "channelNo" } },
                [
                  _c("all-channel", {
                    attrs: { form: _vm.form, type: "payment" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "UTR", prop: "utr" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "utr" },
                    model: {
                      value: _vm.form.utr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "utr", $$v)
                      },
                      expression: "form.utr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单状态", prop: "status" } },
                [
                  _c("order-status-list", {
                    attrs: { form: _vm.form, "search-type": "payOut" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open1,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open1 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form1",
              attrs: {
                model: _vm.form1,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上游订单状态", prop: "status" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.form1.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form1, "status", $$v)
                      },
                      expression: "form1.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }